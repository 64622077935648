import React from "react";
import Footer from "src/components/Footer_uk";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          src="//tt.mbww.com/tt-b028e7b30ae6629575651e0369e15c482a1b97a7d437334f1b1cf00f5c06a78f.js"
          async
        />
        <div id="disclosureText">
          
        </div>
        <div id="page" className="pageFrontpage">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/uk/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/uk/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/uk/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/uk/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/uk/our-products/allergy/">Allergy</a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/aftersun/">After sun</a>
                      </li>                      
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/where-to-buy/" target="_self">
                      Where to buy
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/">UK</a>
                      </li>
                      <li>
                        <a href="/es/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/">PT</a>
                      </li>
                      <li>
                        <a href="/it/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper">
              <div className="home-slider">
                <div className="areaInner">
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".flexslider_edit_disable{width:100%;min-height:20px;background:#999999;padding:10px;text-align:center;color:#fff}\n                        .flexslider_edit_disable.error{color:#cf0000}\n                        a:focus{outline:none!important;}\n                     "
                    }}
                  />
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".flexslider-container3773 {margin:0 auto;position:relative;\t}.flexslider-container3773 img {max-height:450px;}.flexslider-container3773 li {margin-right:0px} .flexslider-container3773 {padding:0px;padding-right:0px;} .flexslider-container3773 img {max-width:100%;}\n                        .flexslider-container3773 {margin:0 auto 0px auto}\n                        .flexslider-container3773 h1 {margin:0;font-size:35px;font-weight:normal;text-align:left;}\n                     "
                    }}
                  />
                  <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                      __html:
                        ' $(window).on(\'load\', function() {$(".flexslider-container3773").flexslider({animation:"slide",direction:"horizontal",reverse:false,animationLoop:true,startAt:0,slideshow :true,slideshowSpeed :7000,animationSpeed :600,initDelay :0,randomize :false,pauseOnAction :false,pauseOnHover :true,smoothHeight :false,useCSS :true,touch :true,video :true,controlNav :true,directionNav :false,keyboard :true,multipleKeyboard :true,mousewheel :false,pausePlay :false,itemMargin:0,minItems:1,maxItems :1,itemWidth: 1680,\nnamespace: "flex-",\nstart: function(){\n$(".flexslider-img-preloader").css("background-image","none");\n$(".flexslider-img-content").css("opacity","1");\n$(".flexslider-img-content").css("visibility","visible");\n}\n});\n});\n'
                    }}
                  />
                  <div className="external-flexslider-container3773 flexslider-img-preloader">
                    <div className="default-flexslider flexslider-container3773 flexslider-img-content">
                      <ul className="slides3773 default-flex-slides slides">                        
                        <li>
                          <a href="/uk/our-products/tan_protect/">
                            {" "}
                            <img src="/assets/images/tan-protect-home-UK.jpg" alt="Protect Your Skin From the Sun" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h1
                                style={{
                                  color: "!important"
                                }}
                              >
                                <br />
                                Enhances natural tanning
                              </h1>
                              <p
                                style={{
                                  color: "!important"
                                }}
                              >
                                PIZ BUIN TAN &amp; PROTECT <sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                href="/uk/our-products/tan_protect/"
                              >
                                Read more
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="/uk/our-products/tan_protect/">
                            {" "}
                            <img src="/assets/images/home-mountain-uk.jpg" alt="Protect Your Skin From the Sun" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                <br />
                                Special protection for sun, cold and wind
                                conditions in high altitude
                              </h3>
                              <p
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                PIZ BUIN MOUNTAIN <sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                href="/uk/our-products/mountain/"
                              >
                                Read more
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    style={{
                      clear: "both"
                    }}
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent clearfix">
                <div className="section">
                  <figure>
                    <a href="/uk/our-products/">
                      <img src="/assets/images/CallToAction_640x370px_ProductRange.jpg" alt="Protect Your Skin From the Sun" />
                    </a>
                  </figure>
                  <div className="titleBlock">
                    <h3>
                      <a href="/uk/our-products/">
                        SUNCARE FOR EVERY NEED
                      </a>
                    </h3>
                    <h4>
                      <a href="/uk/our-products/all_products/">
                        Browse the complete PIZ BUIN<sup>®</sup> product range
                      </a>
                    </h4>
                  </div>
                  <div className="shadow">
                    <br />
                  </div>
                </div>
                <div className="section">
                  <figure>
                    <a href="/uk/know_the_sun/">
                      <img src="/assets/images/CallToAction_640x370px_KnowTheSun.jpg" alt="Protect Your Skin From the Sun" />
                    </a>
                  </figure>
                  <div className="titleBlock">
                    <h3>
                      <a href="/uk/know_the_sun/">KNOW THE SUN</a>
                    </h3>
                    <h4>
                      <a href="/uk/know_the_sun/">
                        Our guide to enjoying the sun safely
                      </a>
                    </h4>
                  </div>
                  <div className="shadow">
                    <br />
                  </div>
                </div>
                <div className="section">
                  <figure>
                    <a href="/uk/our-heritage/">
                      <img src="/assets/images/mobile_landing_OurHeritage_x2.jpg" alt="Protect Your Skin From the Sun" />
                    </a>
                  </figure>
                  <div className="titleBlock">
                    <h3>
                      <a href="/uk/our-heritage/">OUR HERITAGE</a>
                    </h3>
                    <h4>
                      <a href="/uk/our-heritage/">
                        Suncare specialist for over 70 years
                      </a>
                    </h4>
                  </div>
                  <div className="shadow">
                    <br />
                  </div>
                </div>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.instagram.com/PizBuin_UK/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <section id="content" class="competition-terms">
              <h2>LONG TERMS AND CONDITIONS</h2>
              <p class="terms-level-one">1. THE PROMOTER</p>
              <p class="terms-level-two">The Promoter is: Johnson & Johnson Limited of 500-100 Holmers Farm Way, High Wycombe, England, HP12 4EG</p>
              <p class="terms-level-one">2. THE COMPETITION </p>
              <p class="terms-level-two">2.1.	The title of the competition is HIZ BUIN Giveaway. The competition is only open to residents in the United Kingdom, aged 18 years or over, except employees (and members of their immediate families/households) of the Promoter, its affiliated companies, their agencies and anyone professional connected with this competition. The Promoter may ask the winner to provide proof of age.</p>
              <p class="terms-level-two">2.2.	The competition is a prize draw.</p>
              <p class="terms-level-two">2.3.	The promotion is in no way sponsored, endorsed or administered by, or associated with Instagram.</p>
              <p class="terms-level-one">3.	HOW TO ENTER</p>
              <p class="terms-level-two">3.1.	The competition will run from 09:00 on 26/06/2024 (the "Opening Date") to 18:00 on 28/06/2024 (the "Closing Date") inclusive.</p>
              <p class="terms-level-two">3.2.	All competition entries must be received by the Promoter by no later than the Closing Date. All competition entries received after the Closing Date are automatically disqualified.</p>
              <p class="terms-level-two">3.3.	Competition Entry</p>
              <p class="terms-level-three">3.3.1 No purchase necessary.  To enter the prize draw: <br />-	tag @pizbuin_uk & a friend in the post comments.</p>
              <p class="terms-level-two">3.4.	The Promoter will not accept responsibility for competition entries that are lost, mislaid, damaged or delayed in transit, regardless of cause, including, for example, as a result of any postal failure, equipment failure, technical malfunction, systems, satellite, network, server, computer hardware or software failure of any kind; or proof of posting or transmission as proof of receipt of entry to the competition. All internet connection charges and any other expenses which may be incurred due to entering or winning the competition are the sole responsibility of the participant.</p>
              <p class="terms-level-two">3.5.	By submitting a competition entry, you are agreeing to be bound by these terms and conditions.</p>
              <p class="terms-level-two">3.6.	For help with entries, please DM @pizbuin_uk.</p>
              <p class="terms-level-two">3.7.	The winners will be drawn using randomised software or at random under independent supervision from valid entries and notified by direct message within 7 days of the Closing Date.  If the winners cannot be contacted, or does not claim the prize, within 21 days of notification, the winner's claim will become invalid and the Promoter reserves the right at its absolute discretion to withdraw prize entitlement and award the prize to a substitute winner drawn at the same time as the original, following which the Promoter reserves the right at its absolute discretion to withdraw the prize.</p>
              <p class="terms-level-one">4.	ELIGIBILITY</p>
              <p class="terms-level-two">4.1.	In entering the competition, you confirm that you are eligible to do so and eligible to claim the prize you may win. The Promoter may require you to provide proof that you are eligible to enter the competition.</p>
              <p class="terms-level-two">4.2.	The Promoter will not accept competition entries that are: automatically generated by computer; completed by third parties or in bulk; illegible, have been altered, reconstructed, forged or tampered with; photocopies and not originals; or incomplete.</p>
              <p class="terms-level-two">4.3.	There is a limit of one entry to the competition per person. Entries on behalf of another person will not be accepted and joint submissions are not allowed.  Multiple entries may cause you to be disqualified.</p>
              <p class="terms-level-two">4.4.	The Promoter reserves all rights to disqualify you if your conduct is contrary to the spirit or intention of the prize competition and to verify all entries including but not limited to asking for address and identity details (which must be provided within 14 days). The Promoter may refuse to award a prize or withdraw prize entitlement, refuse further participation in the competition and disqualify the participant where there are reasonable grounds to believe there has been a breach of these terms and conditions or any instructions forming part of this competition's entry requirements or otherwise where a participant has gained unfair advantage in participating in the competition or won using fraudulent means.</p>
              <p class="terms-level-two">4.5.	Competition entries cannot be returned.</p>
              <p class="terms-level-one">5.	THE PRIZE</p>
              <p class="terms-level-two">5.1.	The prize is 2x PIZ BUIN® Allergy Sun Sensitive Lotion SPF50 200ml with "HIZ BUIN" branding and there are 50 prizes available to be won.</p>
              <p class="terms-level-two">5.2.	Prizes are subject to availability. There is no cash alternative for the prize. </p>
              <p class="terms-level-two">5.3.	The Promoter reserves the right to replace the prize with an alternative prize of equal or higher value if circumstances beyond the Promoter's control make it necessary to do so. </p>
              <p class="terms-level-two">5.4.	The prize is not negotiable or transferable.</p>
              <p class="terms-level-two">5.5.	You will receive a direct message from @pizbuin_uk informing you that you have won.  In order to claim the prize, you must reply within 21 days of notification, stating you are accepting the prize and confirming the correct postal address to be used to send it to you.</p>
              <p class="terms-level-two">5.6.	Please allow 30 days from claiming the prize for delivery.</p>
              <p class="terms-level-two">5.7.	 The Promoter does not accept any responsibility if you are not able to take up the prize.</p>
              <p class="terms-level-two">5.8.	The prize will be delivered by post. </p>
              <p class="terms-level-one">6.	WINNER ANNOUNCEMENT</p>
              <p class="terms-level-two">6.1.	The decision of the Promoter is final and no correspondence or discussion will be entered into.</p>
              <p class="terms-level-two">6.2.	The Promoter will contact the winners as soon as practicable after the Closing Date, using the contact details provided with the competition entry.  </p>
              <p class="terms-level-two">6.3.	The Promoter will either publish or make available information that indicates that a valid award took place. The Promoter will send the surname and county of the winner to anyone who requests them within one month after the Closing Date of the competition by contacting <a href="mailto:consumer-gb@kenvue.com">consumer-gb@kenvue.com</a>. Should you object to this information being published or made available, please send your objection or a request to reduce the amount of information published/made available to <a href="mailto:consumer-gb@kenvue.com">consumer-gb@kenvue.com</a> within 7 days after the Closing Date. The Promoter will provide information and winning entry to the ASA if challenged.</p>
              <p class="terms-level-one">7.	LIMITATION OF LIABILITY  </p>
              <p class="terms-level-two">Insofar as is permitted by law, the Promoter, its agents or distributors will not in any circumstances be responsible or liable to compensate the winner or accept any liability for any loss, damage, personal injury or death occurring as a result of taking up the prize except where it is caused by the negligence of the Promoter, its agents or distributors or that of their employees. Your statutory rights are not affected.</p>
              <p class="terms-level-one">8.	INTELLECTUAL PROPERTY RIGHTS  </p>
              <p class="terms-level-two">8.1 The Promoter does not claim any rights of ownership in your competition entry.</p>
              <p class="terms-level-one">9.	DATA PROTECTION AND PUBLICITY</p>
              <p class="terms-level-two">9.1.	With your consent, provided as part of entering the competition, if you are the winner, the Promoter may use your name and town or county of residence to announce the winners of this competition.</p>
              <p class="terms-level-two">9.2.	Information regarding personal data, which is provided by you when you submit your entry, will be processed in a database for the purpose of managing this competition only. Such information will not be used for any other purpose than as stated in these 'Terms and Conditions' or as separately accepted by you. Your personal details will at all times be kept confidential and in accordance with the UK GDPR and Data Protection Act 2018. Please visit <a href="/uk/legal/private-policy">https://www.pizbuin.com/uk/legal/private-policy/</a> for the Promoter's Privacy Policy. You can request access to your personal data, or have any inaccuracies rectified, by sending an email to <a href="mailto:consumer-gb@kenvue.com">consumer-gb@kenvue.com</a>. By participating in the competition, you agree to the use of your personal data as described here by the Promoter or its agents and suppliers. You can withdraw your consent at any time, but will no longer be able to participate. If you have separately agreed to opt in to receive communications from the Promoter or a third party then your personal data may be used by them for such purposes, including direct marketing.</p>
              <p class="terms-level-one">10.	GENERAL</p>
              <p class="terms-level-two">10.1.	The Promoter reserves the right to hold void, suspend, cancel, or amend the prize competition where it becomes necessary to do so.</p>
              <p class="terms-level-two">10.2.	These terms and conditions shall be governed by English law, and the parties submit to the non-exclusive jurisdiction of the courts of England and Wales.</p>
            </section>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.min.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <link type="text/css" href="/assets/css/tweaks.css" rel="stylesheet"/>
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n/* <![CDATA[ */\nvar google_conversion_id = 924078965;\nvar google_custom_params = window.google_tag_params;\nvar google_remarketing_only = true;\n/* ]]> */\n"
          }}
        />
        <script
          type="text/javascript"
          src="//www.googleadservices.com/pagead/conversion.js"
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <div style="display:inline">\n            <img height="1" width="1" style="border-style:none" alt="Protect Your Skin From the Sun" src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/924078965/?value=0&amp;guid=ON&amp;script=0">\n         </div>\n      '
          }}
        />
      </div>
    );
  }
}

export default Page;
